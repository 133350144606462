@import "./AboutMeModal.css";

.hello {
  padding-top: 6rem;
  padding-bottom: 3rem;
}
.hello a {
  text-decoration: none;
  color: var(--color-black);
}

.hello-graphics {
  position: relative;
}
.hello-graphics > div {
  position: relative;
  top: 0;
}
.hello-picture {
  height: 314px;
  object-fit: cover;
}
.hello-a {
  position: absolute;
  height: 314px;
  top: -319px;
  right: -346px;
}
.hello-a-bottom-line {
  position: absolute;
  height: 314px;
  top: -317px;
  right: -336px;
}
.hello-content h1 {
  margin-bottom: 1rem;
}

@media (min-width: 960px) {
  .hello {
    padding-bottom: 40px;
  }
  .hello-content {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .hello-content h1 {
    margin-bottom: 24px;
  }
  .hello-graphics {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .hello-picture {
    position: absolute;
    height: 702px;
    width: 370px;
    left: 108px;
    top: 6px;
    right: 0;
    object-fit: contain;
  }
  .hello-a {
    position: absolute;
    top: 9px;
    right: -162px;
    width: 740px;
    height: 698px;
  }
  .hello-a-bottom-line {
    position: absolute;
    right: calc(-57px);
    top: 3px;
    width: 740px;
    height: 710px;
  }
}

@media (min-width: 1220px) {
}
