.testimonials {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.testimonials-slider .slick-slide {
  background: white;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 24px;
  padding-right: 24px;
}
.testimonials-slider .testimonial-quote {
  font-size: 1.3rem;
  font-weight: 600;
}
.testimonials-slider .testimonial-person {
  color: var(--color-blue);
  font-weight: bold;
}
.testimonials-slider .testimonial-person-title {
  font-weight: 300;
}
.testimonials-slider .slick-dots {
  bottom: 0;
}
.testimonials-slider .slick-dots li {
  width: 20px;
  transition: all 0.5s ease;
}
.testimonials-slider .slick-dots li.slick-active {
  width: 50px;
}
.testimonials-slider .slick-dots li button:before {
  border-radius: 4px;
  height: 4px;
  width: 26px;
  background: #c4c4c4;
  content: " ";
  transition: all 0.5s ease;
  opacity: 1;
}
.testimonials-slider .slick-dots li.slick-active button:before {
  width: 56px;
  background: var(--color-blue);
}

.custom-arrows {
  display: none;
}

@media (min-width: 960px) {
  .testimonials {
    padding-top: 15rem;
    padding-bottom: 6rem;
  }

  .testimonials-slider-wrapper {
    position: relative;
  }

  .testimonials-slider .slick-slide {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .testimonials-slider .testimonial-quote {
    font-size: 1.6rem;
    line-height: 2.3rem !important;
    font-weight: 600;
  }
  .testimonials-slider .testimonial-person {
    color: var(--color-blue);
    font-weight: bold;
  }
  .testimonials-slider .testimonial-person-title {
    font-weight: 300;
  }

  .custom-arrows {
    display: block;
    z-index: 9999;
    width: 100%;
    position: absolute;
    top: calc(50% - 56px);
  }
  .custom-arrows .next-arrow,
  .custom-arrows .prev-arrow {
    background: var(--color-white);
    color: var(--color-blue);
    position: absolute;
  }

  .custom-arrows .next-arrow {
    right: 72px;
  }
  .custom-arrows .prev-arrow {
    left: 72px;
  }
}
