.lets-talk {
  padding-top: 10rem;
  padding-bottom: 0rem;
  position: relative;
  overflow: hidden;
}

.form-submit-button {
  margin-top: 0.5rem;
}

.MuiCircularProgress-root {
  display: block !important;
  margin: 5rem auto 5rem auto;
  opacity: 1;
}

.talk-particles {
  position: absolute;
  top: -200px;
  left: 0;
  width: 100vw;
  height: 100%;
  margin-left: -24px;
}

.talk-particle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: all 0.1s ease-in-out;
}

.lets-talk .form-submit-button {
  margin-top: 8px;
  padding: 23px 1.7rem;
}

@media (min-width: 960px) {
  .lets-talk {
    padding-top: 20rem;
    overflow: visible;
  }
}
