.projects {
}
.projects img {
  width: 100%;
}
.projects .project-image {
  margin-bottom: 48px;
  margin-top: 48px;
}

section.project {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.project-picture-container {
}
.project-picture-container img {
  max-width: 100%;
  margin-top: 4rem;
}

.flex-order-1 {
  order: 1;
}
.flex-order-2 {
  order: 2;
}

section.project .MuiTypography-h2 {
  line-height: 1 !important;
  margin-bottom: 18px !important;
}

section.project-vevo-music {
  padding-bottom: 0rem;
}

img.project-google-duo-picture {
}
img.project-xfinity-stream-picture {
}
section.project-vevo-music .project-image {
  right: 0;
  position: relative;
}
img.project-vevo-music-picture {
}
video.project-vevo-music-video {
  display: none;
}

@media (min-width: 960px) {
  section.project {
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  section.project .project-text {
    width: 540px;
  }

  .flex-order-1 {
    order: 0;
  }
  .flex-order-2 {
    order: 0;
  }

  img.project-google-duo-picture {
  }
  img.project-xfinity-stream-picture {
    left: -8px;
    position: relative;
  }
  video.project-vevo-music-video {
    display: block;
    position: absolute;
    top: 34px;
    left: 46px;
    width: 249px;
    height: 456px;
    border-radius: 10px;
  }
  section.project-vevo-music {
    padding-bottom: 6rem;
  }
  section.project-vevo-music .project-image {
    right: 120px;
    position: relative;
  }
  img.project-vevo-music-picture {
  }
}
