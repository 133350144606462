@import "./components/Header.css";
@import "./components/Home.css";
@import "./components/Footer.css";
@import "./components/LetsTalk.css";
/* @import "./components/TestingContent.css"; */

button.next-arrow,
button.prev-arrow,
button.back-to-top-fab {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.04),
    0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.06),
    0px 0px 2px rgba(0, 0, 0, 0.02);
}
button.next-arrow:hover,
button.prev-arrow:hover,
button.back-to-top-fab:hover {
  background: var(--color-white) !important;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.02),
    0px 5px 4px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.06),
    0px -4px 2px rgba(0, 0, 0, 0.02);
}

main {
  overflow: hidden;
}

a:hover {
  color: var(--color-blue) !important;
  transition: 0.2s ease !important;
}
