.simple-is-better {
  padding-top: 10rem;
  padding-bottom: 10rem;
  position: relative;
  overflow: hidden;
}

.simple-particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  margin-left: -24px;
}

.simple-particle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: all 0.1s ease-in-out;
}

@media (min-width: 960px) {
  .simple-is-better {
    padding-top: 20rem;
    padding-bottom: 15rem;
  }
}
