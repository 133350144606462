header .logo svg {
  display: block;
  width: 143px;
  height: 18px;
}
header .logo a {
  display: block;
  width: 143px;
  height: 18px;
}

header .main-navigation a {
  color: var(--color-black);
  text-decoration: none;
  transition: all 0.5s;
  font-size: 0.8rem;
}
header .main-navigation a:hover {
  color: var(--color-blue);
}

header .main-navigation .is-active a {
  color: var(--color-blue);
}

header .resume-tab button {
  border-top-left-radius: 100%;
  border-radius: 50px 4px 4px 50px;
  width: 110px;
  max-width: 110px;
  height: 40px;
  right: -24px;
}
header .resume-tab a,
header .resume-tab a:hover,
header .resume-tab a:visited {
  color: var(--color-white) !important;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
}
