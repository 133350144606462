:root {
  --color-blue: #1300ed;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-light-gray: #e2e2e2;
  --border-radius: 8px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.MuiContainer-root {
  background-color: var(--color-white);
}

.MuiTypography-paragraph {
  margin-bottom: 24px !important;
}

.MuiTypography-body1 {
  line-height: 30px !important;
}

strong,
b {
  font-weight: 600 !important;
}

@media (min-width: 920px) {
}
