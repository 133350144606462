footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: var(--color-white);
}
footer ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
}
footer li {
  margin-right: 8px;
  margin-bottom: 15px;
}
footer li:last-child {
  margin-right: 0;
}

footer li a {
  font-size: 16px;
}
footer li a svg {
  color: var(--color-blue);
  transition: all ease-in-out 200ms;
  border: 1.5px solid var(--color-blue);
  border-radius: 4px;
  padding: 3px;
  text-align: center;
  height: 24px;
  width: 24px;
  display: block;
}
footer li a:hover svg {
  color: var(--color-white);
  background: var(--color-blue);
}
footer .logo {
  display: none;
}
footer .logo svg {
  display: block;
  width: 143px;
  height: 18px;
}

.icon-heart {
  position: relative;
  top: 2px;
  margin-right: 2px;
}

.hide-on-desktop {
  display: block;
}
.beating-heart {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.copyright-text {
  font-size: 14px;
}

@media (min-width: 960px) {
  footer {
    flex-direction: row;
    align-content: space-between;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
  footer li {
    margin-bottom: 0;
  }
  footer .logo {
    display: inherit;
  }
  .hide-on-desktop {
    display: none;
  }
}
