.magic-video {
  display: none;
}

@media (min-width: 960px) {
  .magic-video {
    display: block;
    position: relative;
    height: 600px;
    width: 100%;
    overflow: hidden;
  }
}
